<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons *ngIf="tab !== 'users'" class="tabbar-tile-start" slot="start">
      <ion-button
        *ngIf="!isBlueDevice()"
        class="toolbar-button"
        ion-button
        icon-only
        (click)="showAddDeviceModal()"
      >
        <ion-icon name="custom-add-action"></ion-icon>
        <ion-label *ngIf="!isMobileDevice" class="add-device-button-text"
          >Add device</ion-label
        >
      </ion-button>
    </ion-buttons>

    <ion-buttons class="tabbar-col-center">
      <top-bar-title
        class="tabbar-title-overflow toolbar-title"
      ></top-bar-title>
    </ion-buttons>

    <ion-buttons *ngIf="tab !== 'users'" class="tabbar-tile-end" slot="end">
      <ion-button class="toolbar-button" (click)="save()">Save</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-segment [(ngModel)]="tab" [ngClass]="{ 'segment_ios': isIOSDevice}">
    <ion-segment-button
      *ngIf="!isBlueDevice()"
      class="settings_segment"
      value="sensors"
    >
      <ion-icon name="custom-devices"></ion-icon>
      <ion-label>Devices</ion-label>
    </ion-segment-button>
    <ion-segment-button class="settings_segment" value="vessel">
      <ion-icon name="custom-brnkl"></ion-icon>
      <ion-label>BRNKL</ion-label>
    </ion-segment-button>
    <ion-segment-button
      *ngIf="user && (user?.permission === 0 || user?.permission === 1)"
      class="settings_segment"
      value="users"
    >
      <ion-icon name="people-outline"></ion-icon>
      <ion-label>Users</ion-label>
    </ion-segment-button>
    <ion-segment-button class="settings_segment" value="user">
      <ion-icon name="custom-user"></ion-icon>
      <ion-label>Account</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div [ngSwitch]="tab">
    <configure-sensors
      *ngIf="deviceSettings && !isBlueDevice()"
      [hidden]="tab !== 'sensors'"
      [settings]="deviceSettings"
      [isWirelessKeyfobConnected]="isWirelessKeyfobConnected"
      (settingsChange)="deviceSettingsHandler($event)"
    ></configure-sensors>
    <configure-sensors
      *ngIf="mateSettings && !isBlueDevice()"
      [hidden]="tab !== 'sensors'"
      [settings]="mateSettings"
      (settingsChange)="mateSettingsHandler($event)"
      (removeWirelessSensor)="removeWirelessSensor($event)"
    ></configure-sensors>
    <div *ngSwitchCase="'users'">
      <ion-list *ngIf="userTab === 'users-list'">
        <div class="device_header">Users</div>
        <ion-item
          button
          detail="false"
          *ngFor="let user of users"
          (click)="handleManageUser(user)"
        >
          <ion-label
            >{{user.firstName ? user.firstName + " " + user.lastName :
            user.email }}
          </ion-label>

          <ion-icon name="custom-forward-navigation" slot="end"></ion-icon>
        </ion-item>
        <div class="device_header">Invites</div>
        <ion-item
          button
          detail="false"
          *ngFor="let invite of pendingInvites"
          (click)="handleManageInvite(invite)"
          style="opacity: 0.7"
        >
          <ion-label>{{ invite.email }}</ion-label>
          <ion-icon name="custom-forward-navigation" slot="end"></ion-icon>
        </ion-item>
        <ion-item
          *ngIf="user.permission === 0 || (user.permission === 1 && !noOwner)"
          button
          lines="none"
          detail="false"
          (click)="handleUserTabChange('users-add')"
        >
          <ion-icon name="custom-add-action"></ion-icon>
          <ion-label style="padding-left: 10px">Add user</ion-label>
        </ion-item>
        <ion-item
          *ngIf="user.permission === 1 && noOwner"
          button
          lines="none"
          detail="false"
          (click)="handleUserTabChange('users-add')"
        >
          <ion-icon name="custom-add-action"></ion-icon>
          <ion-label style="padding-left: 10px">Add owner</ion-label>
        </ion-item>
      </ion-list>
      <ion-list *ngIf="userTab === 'users-manage'">
        <div class="device_header" (click)="handleUserTabChange('users-list')">
          <ion-icon name="custom-back-navigation"></ion-icon
          >{{selectedUser.firstName ? selectedUser.firstName + " " +
          selectedUser.lastName : selectedUser.email }}
        </div>
        <ion-item>
          <ion-label>Email</ion-label>
          <ion-input
            class="ion-text-right"
            type="text"
            [(ngModel)]="selectedUser.email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Access</ion-label>
          <ion-input
            class="ion-text-right"
            type="text"
            value="{{selectedUser.permission === 0 ? 'Owner' : selectedUser.permission === 1 ? 'Installer' : 'User'}}"
          ></ion-input>
        </ion-item>
        <ion-item
          *ngIf="user.permission === 0 && selectedUser.permission !== 0 && selectedUser.uid !== currentUser.uid"
          button
          lines="none"
          detail="false"
          (click)="confirmRemoveUser()"
        >
          <ion-label>Remove user</ion-label>
          <ion-icon name="close-outline" slot="end"></ion-icon>
        </ion-item>
      </ion-list>
      <ion-list *ngIf="userTab === 'users-manage-invite'">
        <div class="device_header" (click)="handleUserTabChange('users-list')">
          <ion-icon name="custom-back-navigation"></ion-icon
          >{{selectedInvite.email }}
        </div>
        <ion-item>
          <ion-label>Email</ion-label>
          <ion-input
            class="ion-text-right"
            type="text"
            [(ngModel)]="selectedInvite.email"
          ></ion-input>
        </ion-item>
        <ion-item button detail="false" (click)="handleResendInvite()">
          <ion-label>Send invite again</ion-label>
          <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
        </ion-item>
        <ion-item
          button
          lines="none"
          detail="false"
          (click)="confirmCancelInvite()"
        >
          <ion-label>Cancel invite</ion-label>
          <ion-icon name="close-outline" slot="end"></ion-icon>
        </ion-item>
      </ion-list>
      <ion-list *ngIf="userTab === 'users-add'">
        <div class="device_header" (click)="handleUserTabChange('users-list')">
          <ion-icon name="custom-back-navigation"></ion-icon>Add
          {{user.permission === 1 && noOwner ? 'owner' : 'user'}}
        </div>
        <div class="invite-user-container">
          <ion-input
            class="invite-user-input"
            type="email"
            [(ngModel)]="inviteEmail"
            placeholder="Enter email"
            (ngModelChange)="validateEmail($event)"
            name="invite-email"
          ></ion-input>
          <div class="invite-user-help-text">
            If this user has an existing BRNKL account, enter the email address
            linked to the account.
          </div>
          <action-button
            [disabled]="!emailValid"
            type="primary"
            (action)="handleInviteUser()"
            label="Add {{user.permission === 1 && noOwner ? 'owner' : 'user'}}"
          ></action-button>
          <div
            *ngIf="!inviteIsNotCurrentUser"
            lines="none"
            class="center-warning-text"
          >
            This user already has access to {{deviceSettings.deviceName}}.
          </div>
          <div
            *ngIf="inviteAlreadyExists"
            lines="none"
            class="center-warning-text"
          >
            This user has already been invited to {{deviceSettings.deviceName}}.
          </div>
        </div>
      </ion-list>
    </div>
    <ion-list *ngSwitchCase="'vessel'">
      <div class="device_header">BRNKL</div>
      <ion-item *ngIf="deviceSettings?.deviceName !== undefined">
        <ion-label>Name</ion-label>
        <ion-input
          class="ion-text-right"
          type="text"
          [(ngModel)]="deviceSettings.deviceName"
          maxlength="20"
        ></ion-input>
      </ion-item>
      <ion-item
        button
        detail="false"
        *ngIf="canChangePassword"
        (click)="showChangePasswordModal()"
      >
        Change Vessel Password
        <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
      </ion-item>
      <ion-item (click)="copySerialNumber()">
        <ion-label>Serial number</ion-label>
        <ion-input
          class="ion-text-right"
          [readonly]="true"
          [value]="deviceId$ | async"
        ></ion-input>
      </ion-item>
      <ion-item
        *ngIf="(backendSettings$ | async)?.subscribed && !isBlueDevice()"
        (click)="stripeCustomerPortal()"
      >
        <ion-label>Subscription</ion-label>
        <ion-label slot="end" class="settings_subscription__active"
          >Active</ion-label
        >
        <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
      </ion-item>
      <ion-item
        button
        detail="false"
        *ngIf="!(backendSettings$ | async)?.subscribed && !isBlueDevice()"
        (click)="handleCellularSubscription()"
      >
        <ion-label>Subscription</ion-label>
        <ion-label slot="end" class="settings_subscription__inactive"
          >Inactive</ion-label
        >
        <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
      </ion-item>
      <ion-item button detail="false" (click)="changeDevice()">
        <ion-label>Switch device</ion-label>
        <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
      </ion-item>
      <ion-item
        *ngIf="isBlueDevice()"
        button
        detail="false"
        (click)="openBRNKLUI()"
      >
        <ion-label>BRNKL Device Manager</ion-label>
        <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
      </ion-item>
      <ion-item
        button
        detail="false"
        *ngIf="accountOwner"
        (click)="handleDeviceTransfer()"
      >
        <ion-label>Transfer settings</ion-label>
        <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
      </ion-item>

      <div *ngIf="isMateLinked()">
        <div class="device_header">Mate</div>
        <ion-item>
          <ion-label>Serial number</ion-label>
          <ion-input
            class="ion-text-right"
            [readonly]="true"
            [value]="mateId$ | async"
          ></ion-input>
        </ion-item>
        <ion-item button detail="false" (click)="updateMate()">
          Update
          <ion-label slot="end" class="settings_update-container"
            ><ion-badge *ngIf="mateUpdateAvailable$ | async" color="danger"
              >1</ion-badge
            >
          </ion-label>
          <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
        </ion-item>
        <ion-item button detail="false" (click)="restartMate()">
          Restart
          <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
        </ion-item>
        <ion-item button detail="false" (click)="removeMate()">
          Remove
          <ion-icon slot="end" name="custom-remove-action"></ion-icon>
        </ion-item>
      </div>
      <div *ngIf="isSatelliteLinked()">
        <div class="device_header">Satellite</div>
        <ion-item
          *ngIf="isSatSubscribed"
          (click)="handleSatelliteSubscription()"
        >
          <ion-label>Plan</ion-label>
          <ion-label slot="end"
            >{{isSatSuspended ? 'Paused Satellite' : satellitePlan}}</ion-label
          >
          <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
        </ion-item>
        <ion-item
          *ngIf="!isSatSubscribed"
          (click)="handleSatelliteSubscription()"
        >
          <ion-label>No plan</ion-label>
          <ion-label slot="end">Add plan</ion-label>
          <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
        </ion-item>
        <ion-item>
          <ion-label>Last signal</ion-label>
          <ion-label slot="end">{{lastSatSignal}}</ion-label>
        </ion-item>
        <ion-item
          *ngIf="satelliteSettings?.subscribed"
          button
          detail="false"
          (click)="testSatSignal()"
        >
          Test signal
          <ion-label slot="end">Test</ion-label>
          <ion-icon slot="end" name="custom-forward-navigation"></ion-icon>
        </ion-item>
        <ion-item>
          <ion-label>Serial number</ion-label>
          <ion-label slot="end">{{satelliteSettings.serialNumber}}</ion-label>
        </ion-item>
        <ion-item button detail="false" (click)="testSatCableConnection()">
          Test cable connection
          <ion-label slot="end">{{serialTestStatus}}</ion-label>
          <ion-icon
            *ngIf="serialTestStatus == 'Test'"
            slot="end"
            name="custom-forward-navigation"
          ></ion-icon>
          <ion-icon
            *ngIf="serialTestStatus == 'Connected'"
            slot="end"
            name="custom-complete"
          ></ion-icon>
          <ion-icon
            *ngIf="serialTestStatus == 'Not connected'"
            slot="end"
            class="serial-not-connected"
            name="alert-circle"
          ></ion-icon>
        </ion-item>
        <ion-item button detail="false" (click)="removeSatModem()">
          Remove
          <ion-icon slot="end" name="custom-remove-action"></ion-icon>
        </ion-item>
      </div>
    </ion-list>
    <ion-list *ngSwitchCase="'user'">
      <ion-item>
        <ion-label>Email</ion-label>
        <ion-label class="ion-text-right">{{email}}</ion-label>
      </ion-item>
      <ion-item *ngFor="let userProfileEntry of userProfileEntries">
        <ion-label>{{userProfileEntry.label}}</ion-label>
        <ion-input
          class="ion-text-right"
          type="{{userProfileEntry.type}}"
          [(ngModel)]="userSettings[userProfileEntry.key]"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Email alerts</ion-label>
        <ion-toggle
          [(ngModel)]="userSettings.emailAlertsEnabled"
          color="secondary"
        ></ion-toggle>
      </ion-item>
      <ion-item *ngIf="deviceSettings.linkedMate">
        <ion-label>Wireless Temperature Units</ion-label>
        <ion-select
          [(ngModel)]="userSettings.preferredTempUnit"
          name="preferred-temp-unit"
        >
          <ng-container *ngFor="let unit of unitOptions[sensorTypes.Temp]">
            <ion-select-option value="{{unit}}">{{ unit }}</ion-select-option>
          </ng-container>
        </ion-select>
      </ion-item>
      <ion-item button detail="false" (click)="deleteUserAccount()">
        Delete my account
        <ion-icon slot="end" name="custom-remove-action"></ion-icon>
      </ion-item>
      <ion-item>
        <ion-button
          class="logout-button"
          size="large"
          expand="block"
          (click)="logout()"
          color="danger"
        >
          Logout
        </ion-button>
      </ion-item>
    </ion-list>
    <div class="settings_version" *ngSwitchCase="'user'">
      <div>
        <a href="https://www.brnkl.io/privacy/">Privacy Policy</a>
      </div>
      <div>
        App Version:
        <ion-label>v{{appVersion}}</ion-label>
        <ion-label *ngIf="!isProd"> (Staging)</ion-label>
      </div>
    </div>
  </div>
</ion-content>
