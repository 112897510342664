import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { NgRedux, select } from '@angular-redux/store'
import { Observable, Subscription } from 'rxjs'
import { NavParams, ModalController } from '@ionic/angular'
import { DeviceProvider } from '../../services/device/device.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { AppDevice } from '../../models/app-device.model'
import { AuthProvider } from '../../services/auth/auth.service'
import { EnvironmentProvider } from '../../services/environment/environment.service'
import firebase from 'firebase/compat/app'
import { NavDataService } from 'app/services/navigation/navigation.service'
import { State } from 'app/models/state.model'
import { ShareDeviceStatus } from 'app/models-shared/share-device-status'
import { DeviceUserPermission } from 'app/models-shared/device-user-permission.model'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { NewDeviceStatus } from 'app/models-shared/new-device-status.model'
import { AddServiceModal } from 'app/components/add-service-modal/add-service-modal.component'
import { isBlueDevice } from 'app/util'

export const MIN_PASSWORD_LENGTH: number = 8
export const SEARCH_DEBOUNCE_SEC: number = 1

const defaultDeviceStatus: NewDeviceStatus = {
  exists: false,
  deviceName: null,
  ownerUid: null,
  isOwner: false,
  hasInstaller: false,
  subscribed: false,
  alreadyAdded: false,
  alreadyInvited: false,
  invitingUser: null,
}

@Component({
  selector: 'app-share-device',
  templateUrl: './share-device.page.html',
  styleUrls: ['./share-device.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareDevicePage implements OnInit {
  deviceId: string = ''
  deviceName: string = ''
  deviceStatus: NewDeviceStatus = defaultDeviceStatus
  invitingUser: any
  invitePermission: any
  currentUser: firebase.User = null
  paramDeviceId: string = ''
  paramShareToken: string = ''
  status: ShareDeviceStatus
  subscriptions: Subscription[] = []
  refreshSettings: () => void
  isMobileDevice: boolean

  @ViewChild('scrollPoint') scrollPoint: ElementRef

  @select(['currentUser'])
  currentUser$: Observable<firebase.User>

  @select('allDevices')
  allDevices$: Observable<AppDevice[]>

  constructor(
    public params: NavParams,
    private device: DeviceProvider,
    private helpers: HelpersProvider,
    private auth: AuthProvider,
    private environment: EnvironmentProvider,
    public viewCtrl: ModalController,
    private navData: NavDataService,
    private ngRedux: NgRedux<State>,
    private iap: InAppBrowser
  ) {}

  ngOnDestroy() {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe()
  }

  async ngOnInit() {
    this.isMobileDevice = this.environment.isNativeApp()

    this.subscriptions.push(
      this.currentUser$.subscribe((user: firebase.User) => {
        this.currentUser = user
      })
    )

    // make sure we only compute this once
    this.refreshSettings = this.params.get('refreshSettings')
    this.paramShareToken = this.navData.get('token')
    const response = await this.device.validateShareToken(
      this.paramShareToken,
      this.currentUser.email
    )
    this.deviceId = response.deviceId
    this.deviceStatus = await this.device.deviceStatus(this.deviceId)

    // already added
    // let devices = this.ngRedux.getState().allDevices
    // if (devices.some((device) => device.deviceId === this.deviceId)) {
    //   this.pushTabs(this.deviceId)
    // }
    this.status = response.status
    this.deviceName = response.deviceName
    this.invitingUser = response.invitingUser
    this.invitePermission = response.invitePermission
  }

  launchWebApp() {
    if (this.isMobileDevice) {
      const url = this.environment.webUrl + `/add-device/${this.deviceId}`
      this.iap.create(url, '_system')
    }
  }

  async linkDevice() {
    this.helpers.startLoading('Adding Vessel...')
    let didWork = false
    try {
      await this.device.linkSharedUserToDevice(
        this.deviceId,
        this.deviceName,
        this.invitePermission
      )
      didWork = true
    } catch (err) {
      console.error('Error linking shared user', err)
    }
    await this.helpers.stopLoading()
    if (didWork) {
      this.pushTabs(this.deviceId)
    } else {
      this.showFailToast()
    }
  }

  async handleLinkDevice() {
    if (this.invitePermission === DeviceUserPermission.OWNER) {
      if (!this.deviceStatus.subscribed && !isBlueDevice(this.deviceId)) {
        this.status = ShareDeviceStatus.SUBSCRIBE
      } else {
        this.linkDevice()
      }
    } else {
      this.linkDevice()
    }
  }

  pushTabs(deviceId: string): void {
    this.device.setCurrentDeviceId(deviceId)
  }

  showFailToast(): void {
    this.helpers.showDangerToast('Failed to add device')
  }

  logout(): void {
    this.auth.logout()
  }

  isRoot(): boolean {
    // TODO: find better way too check if root
    //return !this.navCtrl.canGoBack()
    //displays logout button
    return (
      this.ngRedux.getState().allDevices &&
      this.ngRedux.getState().allDevices.length < 1
    )
  }

  showAddServiceModal = () => {
    this.helpers.showModal(
      AddServiceModal,
      {
        refreshSettings: this.refreshSettings,
        serviceType: 'cellular',
        newDeviceId: this.deviceId,
        userRole: 'owner',
      },
      true,
      false,
      'setup-modal-container'
    )
  }
}
