<ion-header>
  <ion-toolbar class="toolbar-background">
    <!-- Map Nav Bar-->
    <ion-buttons slot="start" class="tabbar-tile-start">
      <arm-disarm-button *ngIf="!(anchorOpen$ | async)"></arm-disarm-button>
      <ion-button
        *ngIf="anchorOpen$ | async"
        button
        class="toolbar-button"
        (click)="toggleAnchorOpen()"
        >Cancel</ion-button
      >
    </ion-buttons>
    <ion-buttons class="tabbar-col-center toolbar-title">
      <top-bar-title
        class="tabbar-title-overflow"
        [hidden]="anchorOpen$ | async"
      ></top-bar-title>
      <ion-title *ngIf="anchorOpen$ | async" class="ion-text-center"
        >Set Geofence</ion-title
      >
    </ion-buttons>
    <ion-buttons
      slot="end"
      class="tabbar-tile-end"
      *ngIf="!(anchorOpen$ | async) && !isBlueDevice()"
    >
      <bluetooth-button></bluetooth-button>
      <!-- Must wrap signal-indicator in buttons to get correct position-->
      <ion-button fill="clear" class="top-bar_signal toolbar-button">
        <signal-indicator></signal-indicator>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" class="tabbar-tile-end" *ngIf="anchorOpen$ | async">
      <ion-button button class="toolbar-button" (click)="setAnchor()"
        >Save</ion-button
      >
    </ion-buttons>
    <ion-buttons
      slot="end"
      class="tabbar-tile-end"
      *ngIf="!(anchorOpen$ | async) && isBlueDevice()"
    >
      <notification-button></notification-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  no-padding
  no-bounce
  forceOverscroll="false"
  [class.map_stats-expanded-background]="statsExpanded"
>
  <panel-container>
    <top-panel *ngIf="!statsExpanded">
      <div [class.map__placeholder]="showPlaceholder"></div>
      <agm-map
        #map
        *ngIf="!(currentDevice$ | async)?.gps?.dontTransform && loadMap"
        class="top-panel_inner"
        id="map"
        [mapTypeId]="'hybrid'"
        [disableDefaultUI]="true"
        [class.map__open]="anchorOpen"
        [zoom]="zoomLevel"
        [latitude]="(currentDevice$ | async)?.gps?.latest?.lat"
        [longitude]="(currentDevice$ | async)?.gps?.latest?.long"
        [clickableIcons]="false"
        (mapReady)="emitMapReady()"
      >
        <agm-circle
          *ngFor="let point of (currentDevice$ | async)?.gps?.history"
          [latitude]="point?.lat"
          [longitude]="point?.long"
          [radius]="pointRadius"
          [fillColor]="pointColor(point?.source)"
          [fillOpacity]="pointFillOpacity"
        >
        </agm-circle>
        <agm-marker
          [latitude]="(currentDevice$ | async)?.gps?.latest?.lat"
          [longitude]="(currentDevice$ | async)?.gps?.latest?.long"
        ></agm-marker>
        <agm-circle
          *ngIf="anchorConfig?.lat && anchorConfig?.long"
          [editable]="anchorOpen$ | async"
          [visible]="anchorConfig.enabled ? true : false"
          class="map_geofence"
          [circleDraggable]="anchorOpen$ | async"
          [latitude]="anchorConfig.lat"
          [longitude]="anchorConfig.long"
          [(radius)]="anchorConfig.rad"
          (centerChange)="updateCenter($event)"
          [fillOpacity]="circleFillOpacity"
          [fillColor]="circleColor"
          [strokePosition]="OUTSIDE"
          [strokeWeight]="circleStrokeWeight"
          [strokeColor]="circleColor"
        >
        </agm-circle>
        <ng-container
          *ngIf="(currentDevice$ | async)?.settings?.mapConfig?.showTracks"
        >
          <agm-polyline
            *ngFor="let point of (currentDevice$ | async)?.gps?.history; let i = index;"
            [strokeColor]="pointColor(point?.source)"
            [strokeWeight]="circleStrokeWeight"
            [zIndex]="i"
          >
            <agm-polyline-point
              [latitude]="point?.lat"
              [longitude]="point?.long"
            ></agm-polyline-point>
            <ng-container
              *ngIf="(currentDevice$ | async)?.gps?.history[i+1] as point2"
            >
              <agm-polyline-point
                [latitude]="point2.lat"
                [longitude]="point2.long"
              >
              </agm-polyline-point>
            </ng-container>
          </agm-polyline>
        </ng-container>
      </agm-map>
      <ion-button
        *ngIf="!(anchorOpen$ | async)"
        (click)="openAnchor()"
        shape="round"
        size="small"
        class="map_edit-btn"
        [color]="anchorConfig?.enabled ? 'light' : 'danger'"
      >
        <span>Set Geofence</span>
        <ion-icon
          class="fa fa-anchor icon-margin"
        ></ion-icon>
      </ion-button>
      <ion-button
        (click)="clearMap()"
        shape="round"
        size="small"
        class="map_clear-btn"
        color="light"
      >
        Clear Map
      </ion-button>
    </top-panel>
    <bottom-panel>
      <stats-grid
        *ngIf="!(anchorOpen$ | async)"
        (expandedChange)="toggleStatsExpanded()"
      ></stats-grid>
      <set-anchor
        *ngIf="anchorOpen$ | async"
        [gpsUnits]="(currentDevice$ | async)?.settings?.mapConfig?.gpsUnits"
        [anchorConfig]="anchorConfig"
        [(anchorEnabled)]="anchorConfig.enabled"
      >
      </set-anchor>
    </bottom-panel>
  </panel-container>
</ion-content>
