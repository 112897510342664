import { ActionTypes } from '../../models/action-types.model'
import { Action } from '../../models/action.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'
import { AppDevice } from '../../models/app-device.model'
import { LastUpdated } from '../../models/last-updated.model'
import { Stat } from '../../models-shared/stat.model'
import { State } from '../../models/state.model'
import { formatDate } from './datetime-reducer'
import firebase from 'firebase/compat/app'

export const rootReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.FLUSH_TO_DEFAULT_STATE:
      return action.defaultState
    case ActionTypes.FLUSH_CURRENT_DEVICE:
      return {
        ...state,
        currentDevice: action.defaultDeviceState || {},
      }
    case ActionTypes.USER_TRIGGERED_LOGIN:
      return {
        ...state,
        hasUserTriggeredLogin: true,
      }
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: currentUserReducer(state.currentUser, action),
      }

    case ActionTypes.SET_CURRENT_DEVICE_SETTINGS:
    case ActionTypes.SET_CURRENT_DEVICE_USERS:
    case ActionTypes.SET_CURRENT_DEVICE_CELL_STRENGTH:
    case ActionTypes.SET_CURRENT_DEVICE_GRAPH_LOADING:
    case ActionTypes.SET_CURRENT_DEVICE_GRAPH_DATA:
    case ActionTypes.SET_CURRENT_DEVICE_SENSOR_CONFIG:
    case ActionTypes.SET_CURRENT_DEVICE_ALERTS:
    case ActionTypes.SET_CURRENT_DEVICE_PHOTOS:
    case ActionTypes.SET_CURENT_DEVICE_ANCHOR_CONFIG:
    case ActionTypes.SET_CURRENT_DEVICE_STAT_LAST_UPDATED:
    case ActionTypes.SET_CURRENT_DEVICE_STATUS:
    case ActionTypes.SET_CURRENT_DEVICE_ID:
    case ActionTypes.SET_CURRENT_DEVICE_GPS:
      return {
        ...state,
        currentDevice: currentDeviceReducer(state.currentDevice, action),
      }
    case ActionTypes.SET_ALL_DEVICES:
      return {
        ...state,
        allDevices: action.allDevices.filter((k) => k != null),
      }
    case ActionTypes.SET_CURRENT_USER_SETTINGS:
      return {
        ...state,
        currentUserSettings: {
          ...state.currentUserSettings,
          ...action.currentUserSettings,
        },
      }
    default:
      return state
  }
}

const currentDeviceReducer = (state: AppDevice, action: Action): AppDevice => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_DEVICE_PHOTOS:
      return {
        ...state,
        photos: action.photos,
      }
    case ActionTypes.SET_CURRENT_DEVICE_ALERTS:
      return {
        ...state,
        alerts: action.alerts,
      }
    case ActionTypes.SET_CURRENT_DEVICE_SETTINGS:
    case ActionTypes.SET_CURRENT_DEVICE_SENSOR_CONFIG:
    case ActionTypes.SET_CURENT_DEVICE_ANCHOR_CONFIG:
      return {
        ...state,
        settings: settingsReducer(state.settings, action),
      }
    case ActionTypes.SET_CURRENT_DEVICE_USERS:
      return {
        ...state,
        users: action.users,
      }
    case ActionTypes.SET_CURRENT_DEVICE_GPS:
      return {
        ...state,
        gps: action.gps,
      }
    case ActionTypes.SET_CURRENT_DEVICE_GRAPH_LOADING:
      return {
        ...state,
        isGraphLoading: action.isGraphLoading,
      }
    case ActionTypes.SET_CURRENT_DEVICE_GRAPH_DATA:
      return {
        ...state,
        graphInfo: action.graphInfo,
        nHoursGraphData: action.nHoursGraphData,
      }
    case ActionTypes.SET_CURRENT_DEVICE_CELL_STRENGTH:
      return {
        ...state,
        cellstrength: action.cellstrength,
      }
    case ActionTypes.SET_CURRENT_DEVICE_STATUS: {
      return {
        ...state,
        stats: action.stats,
        statsLastUpdated: lastUpdatedReducer(
          state.statsLastUpdated,
          action.stats
        ),
      }
    }
    case ActionTypes.SET_CURRENT_DEVICE_ID:
      return {
        ...state,
        deviceId: action.deviceId,
      }
  }
  return state
}

const lastUpdatedReducer = (
  state: LastUpdated,
  action: any[],
  format: (datetime: number) => string = formatDate
): LastUpdated => {
  if (!action.length) {
    return {
      datetime: 0,
      formatted: 'N/A',
    }
  }

  const datetimes: number[] = action
    // its important that fake points do not
    // modify the last updated datetime
    .filter((stat: Stat<any>) => !stat.dontTransform)
    .filter(({ datetime }) => datetime != null)
    .map(({ datetime }) => datetime)
  const datetime = datetimes.length ? Math.max(...datetimes) : undefined
  return {
    datetime,
    formatted: format(datetime),
  }
}

const settingsReducer = (state: DeviceSettings, action): DeviceSettings => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_DEVICE_SETTINGS:
      return {
        ...state,
        ...action.settings,
      }
    case ActionTypes.SET_CURRENT_DEVICE_SENSOR_CONFIG:
      return {
        ...state,
        sensorConfig: action.sensorConfig,
      }
    case ActionTypes.SET_CURENT_DEVICE_ANCHOR_CONFIG:
      return {
        ...state,
        anchorConfig: action.anchorConfig,
      }
    default:
      return state
  }
}

const currentUserReducer = (
  state: firebase.User,
  action: Action
): firebase.User => {
  return action.user
}
