import { AgmMap, AgmMarker } from '@agm/core'
import { select } from '@angular-redux/store'
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { isEqual } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { Observable, of } from 'rxjs'
import { switchMap, filter } from 'rxjs/operators'

import { StatsGridComponent } from '../../components/stats-grid/stats-grid.component'
import { AnchorConfig } from '../../models-shared/anchor-config.model'
import { AppDevice } from '../../models/app-device.model'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { AuthProvider } from '../../services/auth/auth.service'
import { IonContent } from '@ionic/angular'
import { StatSources } from '../../models-shared/stat-sources'
import { EnvironmentProvider } from 'app/services/environment/environment.service'
import { DeviceProvider } from 'app/services/device/device.service'
@Component({
  selector: 'app-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapPage implements OnInit {
  @select('currentDevice') currentDevice$: Observable<AppDevice>
  @select(['currentDevice', 'settings', 'anchorConfig'])
  anchorConfig$: Observable<AnchorConfig>
  pausedAnchorConfig$: Observable<AnchorConfig>
  anchorConfig: AnchorConfig
  ogAnchorConfig: AnchorConfig
  anchorOpen$: BehaviorSubject<boolean>

  // Google map config
  nMapPoints: number = 360
  zoomLevel: number = 17
  circleColor: string = '#97c9af'
  circleFillOpacity: number = 0.3
  circleStrokeWeight: number = 3
  pointRadius: number = 2
  cellularPointColor: string = '#d9534f'
  offlinePointColor: string = '#dcdcdc'
  satellitePointColor: string = 'yellow'
  pointFillOpacity: number = 0.9
  loadMap: boolean = false
  isMobileDevice: boolean

  statsExpanded: boolean = false
  showPlaceholder: boolean = true // for when the map is not ready/avail
  toastDuration: number = 5

  @ViewChild('map') map: AgmMap
  @ViewChild(AgmMarker) marker: AgmMarker
  @ViewChild(StatsGridComponent) statsGrid: StatsGridComponent
  @ViewChild(IonContent) content: IonContent

  constructor(
    private helpers: HelpersProvider,
    private settingsProvider: SettingsProvider,
    private environment: EnvironmentProvider,
    private auth: AuthProvider,
    private device: DeviceProvider
  ) {
    this.anchorOpen$ = new BehaviorSubject(false)
    this.isMobileDevice = this.environment.isNativeApp()

    if (!this.isMobileDevice) this.loadMap = true
    // this should only ever emit
    // values while the map is closed
    // Note: this may be worth lifting to
    // a provider at some point
    this.pausedAnchorConfig$ = this.anchorOpen$.pipe(
      switchMap((anchorOpen: boolean, i: number) =>
        !anchorOpen ? this.anchorConfig$ : of(null)
      ),
      filter((k) => k != null)
    )
  }

  ngOnInit(): void {
    this.subscribe()
    this.subscribeToStreams()
  }

  toggleStatsExpanded() {
    this.statsExpanded = !this.statsExpanded
  }

  subscribe(): void {
    this.subscribeToTabChange()
    this.pausedAnchorConfig$.subscribe((anchorConfig: AnchorConfig) => {
      // Set configuration to anchorConfig$ on initial load,
      // otherwise set to ogAnchorConfig (original config) on config dimiss
      this.anchorConfig = this.ogAnchorConfig
        ? this.ogAnchorConfig
        : anchorConfig
      this.ogAnchorConfig = this.ogAnchorConfig
        ? { ...this.anchorConfig }
        : { ...anchorConfig }
    })
  }

  subscribeToStreams(): void {}

  subscribeToTabChange(): void {
    // this ensures the map tiles load if a point is pushed
    // while this page runs in the background
    // TODO: find better way to do this with angular routing
    // this.navCtrl.parent.ionChange
    //   .filter((tab) => tab.tabTitle === 'Map')
    //   .subscribe((tab) => {
    //     if (this.map) this.resizeMap()
    //   })
  }

  ionViewWillEnter(): void {
    if (!this.loadMap) {
      setTimeout(() => {
        this.loadMap = true
      }, 0)
    }
  }

  pointColor(source: number): string {
    if (source == StatSources.Offline) {
      return this.offlinePointColor
    } else if (source == StatSources.Satellite) {
      return this.satellitePointColor
    } else {
      return this.cellularPointColor
    }
  }

  isBlueDevice() {
    return this.device.isBlueDevice()
  }

  emitMapReady(): void {
    this.showPlaceholder = false
  }

  updateCenter(e) {
    this.anchorConfig.lat = e.lat
    this.anchorConfig.long = e.lng
  }

  hasAnchorChanged(): boolean {
    return !isEqual(this.anchorConfig, this.ogAnchorConfig)
  }

  clearMap(): void {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Map cannot be cleared in demo mode.')
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.settingsProvider.clearMap()
  }

  async setAnchor(): Promise<any> {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast(
        'Settings the anchor is not available in demo mode.'
      )
      return
    }

    await this.helpers.startLoading('Setting anchor...')
    try {
      await this.settingsProvider.setAnchorConfig(this.anchorConfig)
      await this.helpers.stopLoading()
      this.helpers.showToast('Success!', this.toastDuration)
    } catch (err) {
      await this.helpers.stopLoading()
      this.helpers.showDangerToast('Failed to set anchor', this.toastDuration)
    }
    this.copyToOg()
    this.toggleAnchorOpen()
  }

  abandonChanges(): void {
    this.copyFromOg()
    this.anchorOpen$.next(false)
  }

  copyToOg(): void {
    this.ogAnchorConfig = { ...this.anchorConfig }
  }

  copyFromOg(): void {
    this.anchorConfig = { ...this.ogAnchorConfig }
  }

  resizeMap(): void {
    if (this.map) this.map.triggerResize(true)
  }

  toggleAnchorOpen(): void {
    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.resizeMap()
    if (this.hasAnchorChanged() && this.anchorOpen$.getValue()) {
      const abandonChanges = this.abandonChanges.bind(this)
      this.helpers.showSimpleAlert(
        'Abandon Changes?',
        'Are you sure you want to dismiss the changes to your anchor?',
        undefined,
        undefined,
        abandonChanges,
        undefined
      )
    } else {
      // set configuration mode to true / false
      this.anchorOpen$.next(!this.anchorOpen$.getValue())
    }
  }

  openAnchor(): void {
    if (!this.anchorConfig) {
      this.anchorConfig = {
        enabled: true,
        lat: this.marker.latitude,
        long: this.marker.longitude,
        rad: 50,
        preferredUnit: 'ft',
      }
    } else {
      this.anchorConfig.enabled = true
      this.anchorConfig.lat = this.marker.latitude
      this.anchorConfig.long = this.marker.longitude
      this.anchorConfig.rad = this.anchorConfig.rad || 50
      this.anchorConfig.preferredUnit = this.anchorConfig.preferredUnit || 'ft'
    }
    this.map.latitude = this.marker.latitude
    this.map.longitude = this.marker.longitude
    this.toggleAnchorOpen()
  }
}
