<ion-header #header>
  <ion-toolbar class="toolbar-background" hideBackButton="true">
    <ion-buttons slot="secondary">
      <ion-button
        class="remove-vessel-button toolbar-button"
        (click)="toggleEdit()"
      >
        <ion-icon
          *ngIf="!editOpen"
          class="remove-vessel-icon"
          name="custom-remove-action"
        ></ion-icon>
        <ion-icon
          *ngIf="editOpen"
          class="remove-vessel-icon-close"
          name="close"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Fleet</ion-title>
    <ion-buttons *ngIf="!editOpen" slot="end">
      <ion-button
        class="add-vessel-button toolbar-button"
        (click)="addVessel()"
      >
        <ion-icon class="add-vessel-icon" name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <panel-container>
    <bottom-panel>
      <device-grid
        (expandedChange)="toggleStatsExpanded()"
        (unlinkDevice)="unlinkDevice($event)"
        [allDevices$]="allDevices$"
        [editOpen]="editOpen"
        (expanded)="expanded"
      ></device-grid>
    </bottom-panel>
    <ion-list *ngIf="!expanded" class="side-list">
      <ion-item
        button
        detail="false"
        (click)="editOpen ? null : pushTabsPage(device)"
        *ngFor="let device of (allDevices$ | async); trackBy: trackById"
      >
        <ion-icon
          *ngIf="deviceOffline(device)"
          slot="start"
          class="offline-icon"
          name="custom-offline-status-bar"
        ></ion-icon>
        <ion-icon
          *ngIf="device?.settings?.isArmed && !deviceOffline(device)"
          slot="start"
          name="custom-armed"
          [color]="'armed'"
        ></ion-icon>
        <ion-icon
          *ngIf="!device?.settings?.isArmed && !deviceOffline(device)"
          slot="start"
          name="custom-disarmed"
          [ngClass]="'disarmed-faded'"
        ></ion-icon>
        <ion-label>{{ device?.settings?.deviceName }}</ion-label>
        <ion-badge
          id="notifications-badge"
          *ngIf="device?.settings?.alertsConfig?.newAlertCount"
          color="danger"
          >{{device?.settings?.alertsConfig?.newAlertCount}}</ion-badge
        >
        <ion-icon
          *ngIf="editOpen"
          slot="end"
          name="trash-outline"
          (click)="unlinkDevice(device.deviceId)"
        ></ion-icon>
        <ion-icon
          *ngIf="!editOpen"
          class="arrow-icon"
          slot="end"
          name="arrow-forward-outline"
        ></ion-icon>
      </ion-item>
    </ion-list>
    <agm-map
      #map
      *ngIf="loadMap"
      [class.map--closed]="expanded"
      id="map"
      [zoom]="zoomLevel"
      [mapTypeId]="'hybrid'"
      [disableDefaultUI]="true"
      [latitude]="latitude"
      [longitude]="longitude"
      [clickableIcons]="false"
      (mapReady)="mapReady($event)"
      (centerChange)="centerChange($event)"
      (zoomChange)="zoomChange($event)"
    >
      <agm-marker
        #marker
        *ngFor="let device of (allDevices$ | async); index as i"
        [class.map__open]="anchorOpen"
        [latitude]="device?.gps?.latest?.lat"
        [longitude]="device?.gps?.latest?.long"
        [label]="{fontSize: '11px', text: device?.settings?.deviceName}"
        [markerClickable]="true"
        (markerClick)="editOpen ? null : pushTabsPage(device)"
        [zIndex]="markerZIndex ? markerZIndex[device?.settings?.deviceName] : 1"
        (mouseOver)="mouseOver(device?.settings?.deviceName)"
      ></agm-marker>
    </agm-map>
    <ion-button
      *ngIf="!expanded"
      (click)="setDefaultView()"
      shape="round"
      slot="start"
      size="small"
      class="map_edit-btn"
      [color]="'background'"
    >
      <ion-label>Save View</ion-label>
      <ion-icon class="icon-margin" name="scan"></ion-icon>
    </ion-button>
  </panel-container>
</ion-content>
