import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  @Input() label: string = 'Action'; // Default label
  @Input() type: 'primary' | 'secondary' = 'primary'; // Default to 'primary'
  @Input() disabled: boolean = false; // Optional disabled flag
  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  // Emit an action when the button is clicked
  onAction() {
    if (!this.disabled) {
      this.action.emit();
    }
  }
}