<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!isRoot()"
        class="toolbar-button"
      ></ion-back-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Add to fleet</ion-title>
    <ion-buttons slot="primary">
      <ion-button
        *ngIf="isRoot()"
        (click)="logout()"
        class="toolbar-button"
        color="danger"
      >
        Logout
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="add-device_bg">
  <div class="add-device_container" *ngIf="(status$ | async) as status">
    <ion-card *ngIf="status === 'SEARCH'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Enter or scan the serial number for the BRNKL you want to
          register</ion-card-title
        >
      </ion-card-header>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <search-device
              [paramDeviceId]="paramDeviceId.trim().toUpperCase()"
              (deviceId$)="onValidDeviceId($event)"
            >
            </search-device>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
    <ion-card *ngIf="status === 'SELECT_ROLE'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header">
          Is this your BRNKL, or are you an installer setting it up for someone
          else?
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-col class="ion-justify-content-center">
            <!-- Owner Button -->
            <action-button
              label="My BRNKL"
              type="primary"
              (action)="userRole = 'owner'; handleNextStep('SUBSCRIBE')"
            ></action-button>
            <action-button
              label="Installer"
              type="secondary"
              (action)="userRole = 'installer'; handleNextStep('NAME_DEVICE')"
            ></action-button>
          </ion-col>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <ion-card *ngIf="status === 'NAME_DEVICE'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Enter a name for {{userRole === 'owner' ? 'your' : 'this'}} BRNKL</ion-card-title
        >
      </ion-card-header>
      <form [formGroup]="addNewDeviceForm" class="add-device_form">
        <ion-item lines="none" class="custom_ion-item">
          <ion-input
            class="custom_input"
            formControlName="deviceName"
            type="text"
            placeholder="Enter name"
          ></ion-input>
        </ion-item>
      </form>
      <action-button
        type="primary"
        label="Next"
        (action)="handleNextStep('REGISTER')"
      >
      </action-button>
    </ion-card>
    <ion-card *ngIf="status === 'INVITED'" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >You're already invited to share {{ deviceStatus.deviceName
          }}</ion-card-title
        >
      </ion-card-header>
      <div class="add-device-body">
        {{deviceStatus.invitingUser.firstName}}
        {{deviceStatus.invitingUser.lastName}} invited you to share. Do you want
        to add {{ deviceStatus.deviceName }} to your fleet?
      </div>
      <action-button
        label="Add BRNKL"
        type="primary"
        (action)="linkDevice()"
      ></action-button>
    </ion-card>
    <ion-card *ngIf="status === 'RESTRICTED' && deviceStatus.hasInstaller" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >An installer is setting up your BRNKL.</ion-card-title
        >
      </ion-card-header>
      <div class="add-device-body">
        Ask the installer for an invite to access this BRNKL
      </div>
      <action-button
        label="Close"
        type="primary"
        (action)="handleClose()"
      ></action-button>
    </ion-card>
    <ion-card *ngIf="status === 'RESTRICTED' && !deviceStatus.hasInstaller" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >This BRNKL is registered to someone else.</ion-card-title
        >
      </ion-card-header>
      <div class="add-device-body">
        Contact the registered owner for permission to add to your fleet.
      </div>
      <action-button
        label="Close"
        type="primary"
        (action)="handleClose()"
      ></action-button>
    </ion-card>
    <ion-card
      *ngIf="status === 'REGISTER' && userRole ==='owner'"
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >{{addNewDeviceForm.controls.deviceName.value || deviceStatus.deviceName}} is registered to your BRNKL account</ion-card-title
        >
      </ion-card-header>
      <action-button
        label="Next"
        type="primary"
        (action)="linkDevice()"
      ></action-button>
    </ion-card>
    <ion-card
      *ngIf="status === 'REGISTER' && userRole ==='installer'"
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Your access to {{addNewDeviceForm.controls.deviceName.value}} will expire after 7
          days</ion-card-title
        >
      </ion-card-header>
      <action-button
        label="Add to fleet"
        type="primary"
        (action)="linkDevice()"
      ></action-button>
    </ion-card>
    <ion-card *ngIf="status === 'SUBSCRIBE' && !isMobileDevice" class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Activate BRNKL</ion-card-title
        >
      </ion-card-header>
      <action-button
        label="Add a BRNKL Subscription"
        type="primary"
        (action)="showAddServiceModal()"
      ></action-button>
    </ion-card>
    <ion-card
      *ngIf="status === 'SUBSCRIBE' && isMobileDevice"
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header">Pay Online</ion-card-title>
      </ion-card-header>
      <div class="add-device_webapp-btn-container">
        <ion-button icon-end (click)="launchWebApp()">
          Open BRNKL web app
        </ion-button>
      </div>
      <p class="add-device_webapp-text">
        Payments are only supported through the BRNKL web app
      </p>
    </ion-card>
    <div #scrollPoint class="add-device_bottom"></div>
  </div>
</ion-content>
