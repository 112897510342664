import { Coord } from '../models/coord.model'
import { AgmMarker } from '@agm/core'
import { Stat } from 'app/models-shared/stat.model'
import { DeviceUserPermission } from 'app/models-shared/device-user-permission.model'
import configureMeasurements, {
  allMeasures,
  AllMeasuresUnits,
} from 'convert-units';
const convert = configureMeasurements(allMeasures);

const getTimeFactor = (unit: string): number => {
  const factors: { [unit: string]: number } = {
    s: 0,
    m: 1,
    h: 2,
  }
  // default to seconds (even though unit is required)
  return factors[unit.toLowerCase()] || 0
}

export const convertFromSeconds = (unit: string, time: number): number => {
  return time / 60 ** getTimeFactor(unit)
}

export const convertToSeconds = (unit: string, time: number): number => {
  const factor = getTimeFactor(unit)
  return time * 60 ** factor
}

export type TimeUnit = 's' | 'm' | 'h' | 'd';

export const convertTime = (value: number, fromUnit: TimeUnit, toUnit: TimeUnit): number => {
    const timeUnitsInSeconds = {
        s: 1,
        m: 60,
        h: 3600,
        d: 86400
    };

    const valueInSeconds = value * timeUnitsInSeconds[fromUnit];
    return valueInSeconds / timeUnitsInSeconds[toUnit];
}

const p =
  (f: any, g: any): any =>
  (...args) =>
    g(f(...args))
export const pipe = (...fn): any => fn.reduce(p)

export const noopPipe = (thing: any): any => thing

interface ConvertUnit {
  to(unit: string): number
}

export const formatUnit = (unit: string): AllMeasuresUnits => { 
  switch (unit) {
     case 'kpa':
      return 'kPa'
     case 'pa':
      return 'Pa'
     case 'hpa':
      return 'hPa'
    case 'kpa':
      return 'kPa'
    case 'inches':
      return 'inHg'
    case 'k':
     return 'K'
    case 'c':
      return 'C'
    case 'f':
      return 'F'
    default: 
     return unit as AllMeasuresUnits
  }
 
}

export const convertUnit = (
  value: number,
  currentUnit: string
): ConvertUnit => {
  return {
    to(desiredUnit: string): number {
      if (desiredUnit === 'mmhg') { // current library doesn't support mmHg conversion
        let inHg = convert(value).from(formatUnit(currentUnit)).to('inHg')
        return inHg * 25.4 // 1 inHg = 25.4 mmHg
      } else if (desiredUnit === 'mbar') { // current library doesn't support mbar conversion
        let hPa = convert(value).from(formatUnit(currentUnit)).to('hPa')
        return hPa // 1 hPa = 1 mbar
      } else if (desiredUnit === 'g') { // impact values come in converted already to g
        return value
      } else {
        let r = value
        try {
          r = convert(value).from(formatUnit(currentUnit)).to(formatUnit(desiredUnit))
        } catch (err) {
          console.error(
            `Failed to convert value ${value} from ${currentUnit} to ${desiredUnit}, ${err}`
          )
        }
        return r
      }
      
    },
  }
}

export const convertLatDecimalToDegrees = (decimalStr: string): string => {
  const decimal = parseFloat(decimalStr)
  const [degrees, minutes, seconds] = convertGPSDecimalToDegrees(decimal)
  let direction = 'N'
  if (decimal <= 0) {
    direction = 'S'
  }

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`
}

export const convertLongDecimalToDegress = (decimalStr: string): string => {
  const decimal = parseFloat(decimalStr)
  const [degrees, minutes, seconds] = convertGPSDecimalToDegrees(decimal)

  let direction = 'E'
  if (decimal <= 0) {
    direction = 'W'
  }

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`
}

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

const convertGPSDecimalToDegrees = (decimal: number) => {
  const absDecimal = Math.abs(decimal)

  const degrees = Math.floor(absDecimal)
  const degreeRemainder = absDecimal - degrees
  if (degreeRemainder <= 0) {
    return [degrees, 0, 0]
  }

  const minutesRaw = degreeRemainder * 60
  const minutes = Math.floor(minutesRaw)
  const minutesRemainder = minutesRaw - minutes
  if (minutesRemainder <= 0) {
    return [degrees, minutes, 0]
  }

  const seconds = Math.round(minutesRemainder * 60)

  return [degrees, minutes, seconds]
}

function rad2degr(rad): number {
  return (rad * 180) / Math.PI
}
function degr2rad(degr): number {
  return (degr * Math.PI) / 180
}

export const getLatLngCenter = (latLngInDegr: AgmMarker[]): Coord => {
  var sumX = 0
  var sumY = 0
  var sumZ = 0

  for (var i = 0; i < latLngInDegr.length; i++) {
    if (latLngInDegr[i].latitude == null || latLngInDegr[i].longitude == null)
      continue
    var latitude = degr2rad(latLngInDegr[i].latitude)
    var longitude = degr2rad(latLngInDegr[i].longitude)
    // sum of cartesian coordinates
    sumX += Math.cos(latitude) * Math.cos(longitude)
    sumY += Math.cos(latitude) * Math.sin(longitude)
    sumZ += Math.sin(latitude)
  }

  var avgX = sumX / latLngInDegr.length
  var avgY = sumY / latLngInDegr.length
  var avgZ = sumZ / latLngInDegr.length

  // convert average x, y, z coordinate to latitude and longtitude
  var lng = Math.atan2(avgY, avgX)
  var hyp = Math.sqrt(avgX * avgX + avgY * avgY)
  var lat = Math.atan2(avgZ, hyp)

  return { lat: rad2degr(lat), long: rad2degr(lng) }
}

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371 // Radius of the earth in km
  var dLat = degr2rad(lat2 - lat1) // deg2rad below
  var dLon = degr2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degr2rad(lat1)) *
      Math.cos(degr2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

function mapBetween(value, from1, to1, from2, to2) {
  return ((value - from1) / (to1 - from1)) * (to2 - from2) + from2
}

export const getDefaultZoom = (markers: AgmMarker[]): number => {
  var max = 0
  for (let mm of markers) {
    if (mm.longitude == null || mm.latitude == null) continue
    max = Math.max(
      max,
      getDistanceFromLatLonInKm(
        mm.latitude,
        mm.longitude,
        markers[0].latitude,
        markers[0].longitude
      )
    )
  }

  var zoom = Math.round(mapBetween(max, 0, 1000, 1, 10))
  return Math.round(clamp(4, 10 - zoom, 10))
}

// TODO: Define a better logging solution (APP-342)
export function debugLog(msg: string) {
  // tslint:disable-next-line
  console.log(`BLE: ${msg}`)
}

export const oneWeek = 60 * 60 * 24 * 7
export const oneMonth = oneWeek * 4

//Current time in seconds
export function unixNow(): number {
  return Math.round(Date.now() / 1000)
}

export function isBlueDevice(deviceId: string): boolean {
  if (!deviceId) return false
  return deviceId.startsWith('BBLK') || deviceId.startsWith('BBLU')
}

export const offlineTimeout = unixNow() - 10800 // Time 3 hours ago (in seconds)

export function filterByMatchingDatetimes(
  lat: Stat<number>[],
  long: Stat<number>[]
): [Stat<number>[], Stat<number>[]] {
  // Create maps from the arrays
  const latMap = new Map(lat.map((entry) => [entry.datetime, entry.val]))
  const longMap = new Map(long.map((entry) => [entry.datetime, entry.val]))

  // Find common timestamps
  const commonTimestamps = new Set(
    [...Array.from(latMap.keys())].filter((timestamp) => longMap.has(timestamp))
  )
  // Filter the original arrays
  const filteredLat = lat.filter((entry) =>
    commonTimestamps.has(entry.datetime)
  )
  const filteredLong = long.filter((entry) =>
    commonTimestamps.has(entry.datetime)
  )
  return [filteredLat, filteredLong]
}

export function isMateSensor (key: string): boolean {
  return key.startsWith('motionW') || key.startsWith('waterW') || key.startsWith('multiW') || key.startsWith('keyfobW')
}

type UidObject = {
  [key: string]: true | { permission?: number }
}

export function getOwnerUid(uids: UidObject): string | null {
  for (const [uid, data] of Object.entries(uids)) {
    if (
      data &&
      typeof data === 'object' &&
      data.permission === DeviceUserPermission.OWNER
    ) {
      return uid
    }
  }
  return null // If no user with permission 0 is found
}