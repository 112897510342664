import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { range } from 'lodash' // TODO stahp lodash
import * as moment from 'moment-timezone'
import { AllMeasuresUnits } from 'convert-units'
import { convertFromSeconds, convertToSeconds, convertUnit } from '../../util'
import { SensorTypes, isSensorWireless } from '../../models-shared/sensor-types'
import { Sensor } from '../../models-shared/sensor.model'
import { SensorUnitOptions } from '../../models-shared/sensor-unit-options.model'
import { BilgeSensor } from '../../models-shared/bilge-sensor.model'
import { ThresholdSensor } from '../../models-shared/threshold-sensor.model'
import { DigitalSensor } from '../../models-shared/digital-sensor.model'
import { SensorConfig } from '../../models-shared/sensor-config.model'
import {
  AnalogSensorTypes,
  AnalogSensor,
} from '../../models-shared/analog-sensor.model'
import { WirelessDigitalSensor } from '../../models-shared/wireless-digital-sensor.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'
import { MateSettings } from '../../models-shared/mate-settings.model'
import { WirelessSensor } from '../../models-shared/wireless-sensor.model'
import { WirelessKeypad } from '../../models-shared/wireless-keypad.model'

import {
  DefaultBattery,
  DefaultAnalogBilge,
  DefaultAnalogIgnition,
  DefaultAnalogOther,
} from '../../models-shared/analog-sensor.default'

import { AuthProvider } from '../../services/auth/auth.service'
import { SettingsProvider } from '../../services/settings/settings.service'
import { HelpersProvider } from '../../services/helpers/helpers.service'
import { BluetoothLEProvider } from '../../services/bluetooth-le/bluetooth-le.service'
import { UserSettings } from '../../models-shared/user-settings.model'
import { BehaviorSubject } from 'rxjs'
import { AlertInput } from '@ionic/angular'

type SensorConfigList = (
  | AnalogSensor
  | ThresholdSensor
  | DigitalSensor
  | BilgeSensor
  | WirelessDigitalSensor
  | WirelessKeypad
  | WirelessSensor
  | Sensor
)[]

const TIME_OPTIONS: string[] = ['s', 'm', 'h']
const HOURS_IN_A_DAY = 24

const offsetMoment = moment.tz(moment.utc(), moment.tz.guess())

const datetimeToIso = (settings: DeviceSettings): DeviceSettings => {
  const lastGpsResetDatetimeIso: string = moment
    .unix(settings.mapConfig.lastGpsResetDatetime)
    .tz(moment.tz.guess())
    .format('YYYY-MM-DDTHH:mm:ssZ')
  return {
    ...settings,
    mapConfig: {
      ...settings.mapConfig,
      lastGpsResetDatetimeIso,
    },
  }
}

const convertBilgeTimes = (
  bilge: BilgeSensor,
  convertHandler: (string, number) => number
): BilgeSensor => {
  return {
    ...bilge,
    longPeriodAlert: convertHandler(
      bilge.longPeriodAlertUnit,
      bilge.longPeriodAlert
    ),
    shortPeriodAlert: convertHandler(
      bilge.shortPeriodAlertUnit,
      bilge.shortPeriodAlert
    ),
    onTimeLimit: convertHandler(bilge.onTimeLimitUnit, bilge.onTimeLimit),
  }
}

const hourBoundCheck = (hour: number) => {
  if (hour < 0) {
    // Wrap backwards
    return HOURS_IN_A_DAY - Math.abs(hour)
  } else if (hour > 23) {
    // Wrap forwards
    return hour - HOURS_IN_A_DAY
  }

  return hour
}

// TODO: Handle non-whole hour timezones
const getTimeZoneOffset = (): number => {
  // Math.floor is required as non-whole number offsets exists
  const hourOffset = Math.floor(offsetMoment.utcOffset() / 60)
  return hourOffset
}

const convertLocalHourToUTC = (localHour: number): number => {
  const utcHour = localHour - getTimeZoneOffset()
  return hourBoundCheck(utcHour)
}

const convertUTCHourToLocal = (utcHour: number): number => {
  const localHour = utcHour + getTimeZoneOffset()
  return hourBoundCheck(localHour)
}

const convertOffset = (
  sensor: ThresholdSensor,
  fromKey: string,
  toKey: string
): ThresholdSensor => {
  const to: string = sensor[toKey]
  const from: string = sensor[fromKey]
  const offset: number = convertUnit(
    +sensor.offset,
    from as AllMeasuresUnits
  ).to(to as AllMeasuresUnits)
  return {
    ...sensor,
    offset,
  }
}

const convertLimits = (
  sensor: ThresholdSensor,
  fromKey: string,
  toKey: string
): ThresholdSensor => {
  const to: string = sensor[toKey]
  const from: string = sensor[fromKey]
  const { highValue, lowValue } = sensor
  return {
    ...sensor,
    ...(highValue || highValue === 0
      ? {
          highValue: convertUnit(highValue, from as AllMeasuresUnits).to(
            to as AllMeasuresUnits
          ),
        }
      : undefined),
    ...(sensor.lowValue || lowValue === 0
      ? {
          lowValue: convertUnit(lowValue, from as AllMeasuresUnits).to(
            to as AllMeasuresUnits
          ),
        }
      : undefined),
  }
}

const convertWirelessSensor = (
  sensor: WirelessDigitalSensor,
  preferredUnit: string,
  convertFromPreferredUnit: boolean
): WirelessDigitalSensor => {
  const { tempConfig } = sensor
  if (tempConfig) {
    const { unit } = tempConfig
    const to: string = convertFromPreferredUnit ? unit : preferredUnit
    const from: string = convertFromPreferredUnit ? preferredUnit : unit
    const { highValue, lowValue } = tempConfig
    return {
      ...sensor,
      tempConfig: {
        ...tempConfig,
        ...(highValue
          ? {
              highValue: convertUnit(highValue, from as AllMeasuresUnits).to(
                to as AllMeasuresUnits
              ),
            }
          : undefined),
        ...(lowValue
          ? {
              lowValue: convertUnit(lowValue, from as AllMeasuresUnits).to(
                to as AllMeasuresUnits
              ),
            }
          : undefined),
      },
    }
  } else {
    return sensor
  }
}

const shouldConvertOffset = (s: ThresholdSensor): boolean =>
  s.offset && shouldConvertUnit(s) && s.type !== SensorTypes.Temp // temperture offsets should be unitless

const shouldConvertUnit = (s: ThresholdSensor): boolean =>
  s.preferredUnit !== s.unit && s.unit != null && s.preferredUnit != null

const isoToDatetime = (settings: DeviceSettings): DeviceSettings => {
  const lastGpsResetDatetime: number = moment(
    settings.mapConfig.lastGpsResetDatetimeIso
  ).unix()
  return {
    ...settings,
    mapConfig: {
      ...settings.mapConfig,
      lastGpsResetDatetime,
    },
  }
}

const convertSensor = (
  sensor: Sensor,
  fromKey: string,
  toKey: string,
  bilgeConvertHandler: (string, number) => number
) => {
  let transformedSensor = sensor
  if (transformedSensor.type === SensorTypes.BilgeActivity) {
    transformedSensor = convertBilgeTimes(
      transformedSensor as BilgeSensor,
      bilgeConvertHandler
    )
  }

  if (shouldConvertOffset(transformedSensor as ThresholdSensor)) {
    transformedSensor = convertOffset(
      transformedSensor as ThresholdSensor,
      fromKey,
      toKey
    )
  }

  if (shouldConvertUnit(transformedSensor as ThresholdSensor)) {
    transformedSensor = convertLimits(
      transformedSensor as ThresholdSensor,
      fromKey,
      toKey
    )
  }
  return transformedSensor
}

// TODO time conversion here
const cameraObjectToList = (
  settings: DeviceSettings | MateSettings
): DeviceSettings | MateSettings => {
  const oldHourSchedule = settings.cameraConfig.cam1.hourSchedule
  const hourScheduleUTC = oldHourSchedule ? Object.values(oldHourSchedule) : []
  const hourSchedule = hourScheduleUTC.map((hour: number) =>
    convertUTCHourToLocal(hour)
  )
  return {
    ...settings,
    cameraConfig: {
      ...settings.cameraConfig,
      cam1: {
        ...settings.cameraConfig.cam1,
        hourSchedule,
      },
    },
  }
}

// TODO another time conversion here
const cameraObjectFromList = (
  settings: DeviceSettings | MateSettings
): DeviceSettings | MateSettings => {
  const hourSchedule = {}
  for (let i = 0; i < settings.cameraConfig.cam1.hourSchedule.length; i++) {
    const hour: number = settings.cameraConfig.cam1.hourSchedule[i]
    hourSchedule[`hour${i}`] = convertLocalHourToUTC(hour)
  }
  return {
    ...settings,
    cameraConfig: {
      ...settings.cameraConfig,
      cam1: {
        ...settings.cameraConfig.cam1,
        hourSchedule,
      },
    },
  }
}

const minPinLength: number = 2
const maxPinLength: number = 8

@Component({
  selector: 'configure-sensors',
  templateUrl: './configure-sensors.component.html',
  styleUrls: ['./configure-sensors.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureSensorsComponent {
  AnalogSensorTypes = AnalogSensorTypes

  unitOptions: SensorUnitOptions = {
    [SensorTypes.Geofence]: ['m', 'km', 'mi', 'ft'],
    [SensorTypes.Temp]: ['C', 'F'],
    [SensorTypes.WirelessTemp]: ['C', 'F'],
    [SensorTypes.Pressure]: ['kPa', 'psi', 'mbar', 'mmhg', 'inches'],
    [SensorTypes.GPS]: ['Decimal', 'Deg Min Sec'],
  }

  excludeAlerts: SensorTypes[] = [
    SensorTypes.Pressure,
    SensorTypes.WirelessKeypad,
    SensorTypes.WirelessKeyfob,
  ]

  excludeShowOnGrid: SensorTypes[] = [
    SensorTypes.WirelessKeypad,
    SensorTypes.WirelessKeyfob,
  ]

  analogSensorNames: string[] = ['Battery', 'Bilge', 'Ignition', 'Other']
  rotationOptions: number[] = [0, 90, 180, 270]
  bilgeTimeUnitOptions: string[] = TIME_OPTIONS

  sensorTypes = SensorTypes
  decimalOptions: number[] = range(5)
  floatPattern: string = `[+-]?([0-9]*[.])?[0-9]+`
  hourSchedule: number[] = [7, 11, 15]
  delayOptions: number[] = [0, 30, 45, 60, 120, 180]

  private _settings: DeviceSettings | MateSettings
  sensorConfigList: SensorConfigList
  userSettings$: BehaviorSubject<UserSettings>

  get pageReady(): boolean {
    return this.settings ? this.sensorConfigList != null : false
  }

  // Nothing special here. We could do the transform
  // here but that seems less efficient
  get settings(): DeviceSettings | MateSettings {
    return this._settings
  }

  // Whenever the input changes it's required to re-transform it
  @Input('settings')
  set settings(val: DeviceSettings | MateSettings) {
    this._settings = this.settingsToFormTransform(val)
  }

  @Input('isWirelessKeyfobConnected') isWirelessKeyfobConnected: boolean

  // The state of the form gets passed up on this output
  // Make sure to emit using this.emit() since that is when the transform is applied
  @Output()
  settingsChange: EventEmitter<DeviceSettings | MateSettings> =
    new EventEmitter()

  @Output()
  removeWirelessSensor: EventEmitter<WirelessSensor> = new EventEmitter()

  constructor(
    private bluetoothProvider: BluetoothLEProvider,
    private settingsProvider: SettingsProvider,
    private helpers: HelpersProvider,
    private auth: AuthProvider
  ) {
    this.userSettings$ = this.settingsProvider.userSettings$
  }

  hasAlertsEnabledButton(sensorType: SensorTypes): boolean {
    return !this.excludeAlerts.includes(sensorType)
  }

  hasShowOnGridButtonButton(sensorType: SensorTypes): boolean {
    return !this.excludeShowOnGrid.includes(sensorType)
  }

  emit() {
    const result = this.formToSettingsTransform(this.settings)
    this.settingsChange.emit(result)
  }

  parseFloat(e: string): number {
    return +e
  }

  parseBoolean(e: string): boolean {
    return e === 'true'
  }

  range(start?: number, end?: number, step?: number) {
    return range(start, end, step)
  }

  isValidValue(obj: any): boolean {
    return obj !== undefined && obj !== null
  }

  isWirelessSensor(sensorType: SensorTypes): boolean {
    return isSensorWireless(sensorType)
  }

  async calibrate() {
    if (this.auth.isDemoAccount()) {
      this.helpers.showToast('Cannot calibrate vessel in demo mode.')
      return
    }

    if (this.settingsProvider.isViewer()) {
      this.helpers.showToast('Not available as a Viewer.')
      return
    }

    this.helpers.startLoading('Calibrating...')
    try {
      await this.settingsProvider.calibrateAccelerometer()
      this.helpers.stopLoading()
      this.helpers.showToast('Success!')
    } catch (err) {
      this.helpers.stopLoading()
      this.helpers.showDangerToast('Failed to calibrate')
    }
  }

  pinSaveHandler = async (data: { PIN: string }) => {
    const pin = data.PIN

    if (minPinLength > pin.length || pin.length > maxPinLength) {
      this.helpers.showDangerToast('PIN must be 2-8 digits!')
      return this.showWirelessKeypadChangePin(this.pinSaveHandler)
    }

    const pinNumber = parseInt(pin)

    //This case should never occur under normal circumstances, so fail outright.
    if (!pinNumber) {
      return this.helpers.showToast(
        'Failed to change the PIN. Please try again!'
      )
    }

    this.helpers.startLoading('Changing Pin...')
    try {
      await this.bluetoothProvider.changeKeypadPin(pinNumber)
      await this.helpers.stopLoading()
      this.helpers.showToast('PIN successfully changed!')
    } catch {
      await this.helpers.stopLoading()
      this.helpers.showToast('Failed to change the PIN. Please try again!')
    }
  }

  changeWirelessKeypadPinPrompt() {
    this.showWirelessKeypadChangePin(this.pinSaveHandler)
  }

  showWirelessKeypadChangePin = (saveHandler: any) => {
    const connected = this.bluetoothProvider.connected.getValue()
    const alertInputsHandler: AlertInput[] = [
      {
        type: 'tel',
        name: 'PIN',
        placeholder: 'PIN (2-8 digits)',
        label: 'PIN',
        min: maxPinLength,
        max: minPinLength,
      },
    ]

    if (connected) {
      this.helpers.showInputPrompt(
        'Change PIN',
        undefined,
        alertInputsHandler,
        'Change the PIN code on your keypad.',
        undefined,
        undefined,
        saveHandler,
        undefined
      )
    } else {
      this.helpers.showDangerToast(
        "You must connect to the Mate to change the keypad's PIN!"
      )
    }
  }

  disableEntryExitDelayPrompt() {
    const connected = this.bluetoothProvider.connected.getValue()
    return !connected
  }

  checkBluetoothConnection() {
    const connected = this.bluetoothProvider.connected.getValue()
    if (!connected) {
      this.helpers.showDangerToast(
        "You must connect to the Mate to change the keypad's entry/exit delay!"
      )
    }
  }

  wirelessKeypadExists() {
    for (const sensor in this.settings.sensorConfig) {
      if (
        this.settings.sensorConfig[sensor].type === SensorTypes.WirelessKeypad
      ) {
        return true
      }
    }
    return false
  }

  switchAnalogType(sensorKey: string, analogType: number) {
    // Get default analog config
    let newAnalogConfig: Partial<AnalogSensor>
    const analogInputNum = parseInt(sensorKey.slice(sensorKey.length - 1))
    if (analogType === AnalogSensorTypes.Battery) {
      newAnalogConfig = DefaultBattery(`Battery ${analogInputNum}`, null)
    } else if (analogType === AnalogSensorTypes.Bilge) {
      newAnalogConfig = DefaultAnalogBilge()
    } else if (analogType === AnalogSensorTypes.Ignition) {
      newAnalogConfig = DefaultAnalogIgnition()
    } else {
      newAnalogConfig = DefaultAnalogOther(analogInputNum)
    }

    // Modify existing config
    const sensorConfig: AnalogSensor = <any>(
      this.sensorConfigList.find((sensor) => sensor.key === sensorKey)
    )

    sensorConfig.name = newAnalogConfig.name
    sensorConfig.steppedLine = newAnalogConfig.steppedLine
    sensorConfig.highValue = newAnalogConfig.highValue

    if (analogType !== AnalogSensorTypes.Battery) {
      // Enable additional settings
      sensorConfig.lowName = newAnalogConfig.lowName
      sensorConfig.highName = newAnalogConfig.highName
      sensorConfig.alertOnHigh = newAnalogConfig.alertOnHigh
      sensorConfig.triggerValue = newAnalogConfig.triggerValue
      if (analogType !== AnalogSensorTypes.Ignition) {
        sensorConfig.engineHours = null
        sensorConfig.offset = 0
      } else {
        sensorConfig.engineHours = newAnalogConfig.engineHours
        sensorConfig.offset = null
      }

      // Disable battery settings
      sensorConfig.highValue = null
      sensorConfig.lowValue = null
      sensorConfig.nDecimals = null
    } else {
      // Enable battery settings
      sensorConfig.highValue = newAnalogConfig.highValue
      sensorConfig.lowValue = newAnalogConfig.lowValue
      sensorConfig.nDecimals = newAnalogConfig.nDecimals
      sensorConfig.offset = newAnalogConfig.offset

      // Disable additional settings
      sensorConfig.lowName = null
      sensorConfig.highName = null
      sensorConfig.alertOnHigh = null
      sensorConfig.triggerValue = null
      sensorConfig.engineHours = null
    }
  }

  getWirelessSensorPreferredTempUnit = () => {
    return this.userSettings$.value.preferredTempUnit
  }

  private settingsToFormTransform = (
    settings: DeviceSettings | MateSettings
  ): DeviceSettings | MateSettings => {
    let result = (<DeviceSettings>settings).mapConfig
      ? datetimeToIso(settings)
      : settings

    result = this.sensorConfigToForm(result)
    return result.cameraConfig ? cameraObjectToList(result) : result
  }

  // now we just need to convert back and decide
  private formToSettingsTransform = (
    formSettings: DeviceSettings | MateSettings
  ): DeviceSettings | MateSettings => {
    let result = (<DeviceSettings>formSettings).mapConfig
      ? isoToDatetime(formSettings)
      : formSettings

    result = this.formToSensorConfig(result)
    return result.cameraConfig ? cameraObjectFromList(result) : result
  }

  private sensorConfigToForm = (
    settings: DeviceSettings | MateSettings
  ): DeviceSettings | MateSettings => {
    const sensorTransform = (sensor: Sensor) =>
      isSensorWireless(sensor.type)
        ? convertWirelessSensor(
            <WirelessDigitalSensor>sensor,
            this.getWirelessSensorPreferredTempUnit(),
            false
          )
        : convertSensor(sensor, 'unit', 'preferredUnit', convertFromSeconds)

    this.sensorConfigList = settings.sensorConfig
      ? Object.values(settings.sensorConfig).map(sensorTransform)
      : []
    return settings
  }

  private formToSensorConfig = (
    settings: DeviceSettings | MateSettings
  ): DeviceSettings | MateSettings => {
    const sensorReverseTransform = (sensor: Sensor) =>
      isSensorWireless(sensor.type)
        ? convertWirelessSensor(
            <WirelessDigitalSensor>sensor,
            this.getWirelessSensorPreferredTempUnit(),
            true
          )
        : convertSensor(sensor, 'preferredUnit', 'unit', convertToSeconds)

    const transformedSensors: Sensor[] = this.sensorConfigList.map(
      sensorReverseTransform
    )

    const sensorConfig: SensorConfig = transformedSensors.reduce(
      (config: SensorConfig, sensor: Sensor) => ({
        ...config,
        [sensor.key]: sensor,
      }),
      {}
    )

    // clean out the list before
    const { ...rest } = settings
    return {
      ...rest,
      sensorConfig,
    }
  }

  public showOutputOnAlert = (type: SensorTypes): boolean => {
    switch (type) {
      case SensorTypes.Motion:
      case SensorTypes.Digital:
      case SensorTypes.WirelessMotion:
      case SensorTypes.WirelessMulti:
      case SensorTypes.WirelessWater:
        return true
      default:
        return false
    }
  }
}
