<ion-header>
  <ion-toolbar class="toolbar-background">
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!isRoot()"
        class="toolbar-button"
      ></ion-back-button>
    </ion-buttons>
    <ion-title class="toolbar-title">Add to fleet</ion-title>
    <ion-buttons slot="primary">
      <ion-button
        *ngIf="isRoot()"
        (click)="logout()"
        class="toolbar-button"
        color="danger"
      >
        Logout
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="share-device_bg" *ngIf="status === 'VALID'">
  <div class="share-device_container">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="share-device-header"
          >You've been invited to share {{ deviceName }}.</ion-card-title
        >
      </ion-card-header>
      <ion-card-content>
        <p class="share-device-text">
          {{invitingUser?.firstName ?? A}} {{invitingUser?.lastName ?? friend}}
          invited you to share. Do you want to add {{ deviceName }} to your
          fleet?
        </p>
        <div class="share-device_btn-container">
          <action-button
            label="Add BRNKL"
            type="primary"
            (action)="handleLinkDevice()"
          ></action-button>
        </div>
      </ion-card-content>
    </ion-card>
    <div #scrollPoint class="share-device_bottom"></div>
  </div>
</ion-content>
<ion-content class="share-device_bg" *ngIf="status === 'INVALID_EMAIL'">
  <div class="share-device_container">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="share-device-header">
          This invite was sent to an email address different from your BRNKL
          account email.
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p class="share-device-text">
          To share {{ deviceName ?? 'this device'}}, ask
          {{invitingUser?.firstName ?? 'the device'}} {{invitingUser?.lastName
          ?? 'owner'}} to send an invite to the email address linked to your
          BRNKL account.
        </p>
      </ion-card-content>
    </ion-card>
    <div #scrollPoint class="share-device_bottom"></div>
  </div>
</ion-content>
<ion-content class="share-device_bg" *ngIf="status === 'INVITE_EXPIRED'">
  <div class="share-device_container">
    <ion-card class="ion-card-bg">
      <ion-card-header>
        <ion-card-title class="share-device-header"
          >This invitation has expired.</ion-card-title
        >
      </ion-card-header>
      <ion-card-content>
        <p class="share-device-text">
          To share {{ deviceName }}, ask the owner to send the invite again.
        </p>
      </ion-card-content>
    </ion-card>
    <div #scrollPoint class="share-device_bottom"></div>
  </div>
</ion-content>
<ion-content class="share-device_bg" *ngIf="status === 'SUBSCRIBE' && !isMobileDevice">
  <div class="share-device_container">
    <ion-card
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header"
          >Activate BRNKL</ion-card-title
        >
      </ion-card-header>
      <action-button
        label="Add a BRNKL Subscription"
        type="primary"
        (action)="showAddServiceModal()"
      ></action-button>
    </ion-card>
  </div>
</ion-content>
<ion-content class="share-device_bg" *ngIf="status === 'SUBSCRIBE' && isMobileDevice">
  <div class="share-device_container">
    <ion-card
     
      class="ion-card-bg"
    >
      <ion-card-header>
        <ion-card-title class="add-device-header">Pay Online</ion-card-title>
      </ion-card-header>
      <div class="add-device_webapp-btn-container">
        <ion-button icon-end (click)="launchWebApp()">
          Open BRNKL web app
        </ion-button>
      </div>
      <p class="add-device_webapp-text">
        Payments are only supported through the BRNKL web app
      </p>
    </ion-card>
  </div>
</ion-content>
