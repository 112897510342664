import { Injectable } from '@angular/core'
import bcrypt from 'bcryptjs'
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { BehaviorSubject } from 'rxjs'
import { Observable, of } from 'rxjs'
import {
  mergeWith,
  map,
  filter,
  takeUntil,
  catchError,
  switchMap,
} from 'rxjs/operators'

import { AppDevice } from '../../models/app-device.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'
import { AuthProvider } from '../auth/auth.service'
import { CloudFunctionsProvider } from '../cloud-functions/cloud-functions.service'
import { DbPathsProvider } from '../db-paths/db-paths.service'
import { CurrentIds } from '../../models/ids.model'
import { FunctionsRes } from '../../models-shared/functions-res.model'
import { SatelliteSubscribers } from '../../models/satellite-subscribers.model'
import { combineLatest } from 'rxjs'
import { ENV } from '@app/env'
import { ShareDeviceStatus } from 'app/models-shared/share-device-status'

const checkSuccess = (body) => {
  if (!body.success) throw new Error('Failed on server')
}

@Injectable({
  providedIn: 'root',
})
export class DeviceProvider {
  public deviceUnselected$: Observable<string>
  public mateChanged$: Observable<string>
  public satModemChanged$: Observable<string>

  public currentBRNKLandMateId$: BehaviorSubject<CurrentIds> =
    new BehaviorSubject({})
  public allDevices$: Observable<AppDevice[]>

  private currentDeviceId$: BehaviorSubject<string> = new BehaviorSubject(null)
  private currentMateId$: Observable<string>
  private currentSatModemId$: Observable<string>
  constructor(
    private auth: AuthProvider,
    private paths: DbPathsProvider,
    private db: AngularFireDatabase,
    private cloudFunctions: CloudFunctionsProvider
  ) {
    this.currentMateId$ = this.getMate()
    this.currentSatModemId$ = this.getSatModem()
    this.deviceUnselected$ = this.currentDeviceId$.pipe(
      filter((k, i) => k == null && i > 0),
      mergeWith(this.auth.logouts$)
    )

    this.mateChanged$ = this.currentMateId$.pipe(
      filter((k, i) => i > 0),
      mergeWith(this.auth.logouts$)
    )
    this.satModemChanged$ = this.currentSatModemId$.pipe(
      filter((k, i) => i > 0),
      mergeWith(this.auth.logouts$)
    )
    this.allDevices$ = this.getDevices()

    combineLatest([
      this.currentDeviceId$,
      this.currentMateId$,
      this.currentSatModemId$,
    ]).subscribe((vals) => {
      this.currentBRNKLandMateId$.next({
        deviceId: vals[0],
        mateId: vals[1],
        satModemId: vals[2],
      })
    })
  }

  public setCurrentDeviceId(deviceId?: string): void {
    this.currentDeviceId$.next(deviceId)
  }

  private getMinimumDeviceSettings(key: string): Observable<AppDevice> {
    let deviceSettings$: Observable<AppDevice> = this.db
      .object(this.paths.deviceSettings(key))
      .valueChanges()
      .pipe(
        takeUntil(this.auth.logouts$),
        filter((k: DeviceSettings) => k != null),
        map(
          (k: DeviceSettings): AppDevice => ({
            settings: {
              deviceName: k.deviceName || `No name (${key})`,
              isArmed: k.isArmed,
              lastUpdated: k.lastUpdated,
              alertsConfig: k.alertsConfig,
            },
            deviceId: key,
          })
        ),
        catchError((err, caught) => {
          // Permissions error...device was deleted ignore.
          return of(null)
        })
      )

    let deviceLatestStatus$: Observable<AppDevice> = this.db
      .object(this.paths.deviceLatestStatus(key))
      .valueChanges()
      .pipe(
        takeUntil(this.auth.logouts$),
        map((data: any) => ({
          gps: {
            latest: {
              lat: data.lat.val,
              long: data.long.val,
            },
          },
        })),
        catchError((err, caught) => {
          // Permissions error...device latestStatus was deleted ignore.
          return of({ gps: { latest: null } })
        })
      )

    return combineLatest([deviceSettings$, deviceLatestStatus$]).pipe(
      map(([deviceSettings, deviceLatestStatus]: [AppDevice, AppDevice]) => {
        return {
          settings: deviceSettings.settings,
          deviceId: deviceSettings.deviceId,
          gps: deviceLatestStatus.gps,
        }
      }),
      catchError((err, caught) => {
        return of(null)
      })
    )
  }

  private getDevices(): Observable<AppDevice[]> {
    return this.auth.user.pipe(
      filter((user) => user != null),
      filter((user) => user.uid != null),
      switchMap((user) =>
        this.db
          .object(this.paths.deviceList(user.uid))
          .valueChanges()
          .pipe(takeUntil(this.auth.logouts$))
      ),
      switchMap((devices: { [deviceKey: string]: boolean }) => {
        if (devices == null) {
          devices = {}
        }

        const deviceList = Object.keys(devices).filter((k) => k[0] !== '$')
        if (!deviceList.length) {
          return of(deviceList)
        }
        const devices$: Observable<any>[] = deviceList.map((key: string) =>
          key !== '$value' ? this.getMinimumDeviceSettings(key) : of(null)
        )

        return combineLatest([...devices$])
      })
    )
  }

  private getMate(): Observable<string> {
    return this.currentDeviceId$.pipe(
      switchMap((deviceId) => {
        if (deviceId != null) {
          return this.db
            .object<string>(this.paths.linkedMate(deviceId))
            .valueChanges()
            .pipe(takeUntil(this.deviceUnselected$))
        } else {
          return of(null)
        }
      })
    )
  }

  private getSatModem(): Observable<string> {
    return this.currentDeviceId$.pipe(
      switchMap((deviceId) => {
        if (deviceId != null) {
          return this.db
            .object<string>(this.paths.linkedSatModem(deviceId))
            .valueChanges()
            .pipe(takeUntil(this.deviceUnselected$))
        } else {
          return of(null)
        }
      })
    )
  }

  public deviceHasUsers(deviceId: string): Promise<boolean> {
    return this.cloudFunctions.authedGetPromise(`deviceHasUsers/${deviceId}`)
  }

  public async getDeviceUsers(deviceId: string): Promise<any> {
    const users: any = await this.cloudFunctions.authedGetPromise(
      `users/${deviceId}`
    )
    return users
  }

  public deviceStatus(deviceId: string): Promise<any> {
    return this.cloudFunctions.authedGetPromise(`newdevicestatus/${deviceId}`)
  }

  public async getDeviceName(deviceId: string): Promise<string> {
    const device: AppDevice = await this.getMinimumDeviceSettings(
      deviceId
    ).toPromise()
    return device.settings.deviceName
  }

  public async linkDevice(
    deviceId: string,
    deviceName?: string,
    userRole?: string
  ): Promise<any> {
    const body = await this.cloudFunctions.authedPost(`linkUser/${deviceId}`, {
      ...(deviceName ? { deviceName: deviceName } : undefined),
      userRole,
    })
    checkSuccess(body)
  }

  public async linkSharedUserToDevice(
    deviceId: string,
    deviceName?: string,
    userRole?: string
  ): Promise<any> {
    
    const body = await this.cloudFunctions.authedPost(`linkUser/${deviceId}`, {
      ...(deviceName ? { deviceName: deviceName } : undefined),
      ...(userRole ? { userRole } : undefined),
    })
    checkSuccess(body)
  }

  public async removeSharedUserFromDevice(
    deviceId: string,
    uid: string,
    deviceOwner: string,
  ): Promise<any> {
    const body = await this.cloudFunctions.authedDel(`users/${deviceId}`, {
      uid,
      deviceOwner,
    })
    checkSuccess(body)
  }

  public async validateShareToken(
    token: string,
    email: string
  ): Promise<{
    status: ShareDeviceStatus
    deviceId: string
    deviceName?: string
    invitingUser?: { firstName: string; lastName: string }
    invitePermission?: number
  }> {
    let deviceId: string
    let deviceName: string
    let invitingUser: { firstName: string; lastName: string }
    let invitePermission: number
    try {
      const response: any = await this.cloudFunctions.authedPost(
        `inviteTokenValidate`,
        { token, email }
      )
      deviceId = response.deviceId
      deviceName = response.deviceName
      invitingUser = response.invitingUser
      invitePermission = response.invitePermission

      if (!response.isTokenValid) {
        return {
          status: ShareDeviceStatus.INVITE_EXPIRED,
          deviceId: null,
          deviceName,
          invitingUser,
          invitePermission,
        }
      } else {
        if (response.isEmailMatch) {
          return {
            status: ShareDeviceStatus.VALID,
            deviceId,
            deviceName,
            invitingUser,
            invitePermission,
          }
        } else {
          return {
            status: ShareDeviceStatus.INVALID_EMAIL,
            deviceId,
            deviceName,
            invitingUser,
            invitePermission,
          }
        }
      }
    } catch (err) {
      return {
        status: ShareDeviceStatus.INVITE_EXPIRED,
        deviceId,
        deviceName,
        invitingUser,
        invitePermission,
      }
    }
  }

  public async unlinkDevice(deviceId: string): Promise<any> {
    const body = await this.cloudFunctions.authedDel(
      `link/${deviceId}`,
      undefined
    )
    checkSuccess(body)
  }

  public canChangePassword(
    deviceId: string = this.currentDeviceId$.getValue()
  ): Promise<any> {
    return this.cloudFunctions.authedGetPromise(`devicepassword/${deviceId}`)
  }

  public async changePassword(
    password: string,
    deviceId: string = this.currentDeviceId$.getValue()
  ): Promise<any> {
    const hash: string = await bcrypt.hash(password, ENV.SALT)
    const body = await this.cloudFunctions.authedPost(
      `devicepassword/${deviceId}`,
      {
        devicePassword: hash,
      }
    )
    checkSuccess(body)
  }

  isMateLinked() {
    const mateId = this.currentBRNKLandMateId$.value.mateId
    return mateId != null
  }

  isSatelliteLinked() {
    const satModemId = this.currentBRNKLandMateId$.value.satModemId
    return satModemId != null
  }

  public isBlueDevice() {
    return (
      this.currentDeviceId$.value.startsWith('BBLK') ||
      this.currentDeviceId$.value.startsWith('BBLU')
    )
  }

  //  links the mate to BRNKL device in database
  public async linkMateToBrnklDB(
    mateId: string,
    deviceId: string
  ): Promise<boolean> {
    const body = {
      mateId,
    }
    const linkEndpoint = `mate/link/${deviceId}`
    const res = await this.cloudFunctions.authedPost<FunctionsRes<void>>(
      linkEndpoint,
      body
    )
    return res.success
  }

  //  unlinks the mate to BRNKL device in database
  public async unlinkMateFromBrnklDB(deviceId: string): Promise<boolean> {
    const unlinkEndpoint = `mate/link/${deviceId}`
    const res = await this.cloudFunctions.authedDel<FunctionsRes<void>>(
      unlinkEndpoint
    )
    return res.success
  }

  public setSerialDevice = async (deviceId: string, serialDevice: string) => {
    const body = await this.cloudFunctions.authedPost(
      `selectSerialDevice/${deviceId}`,
      {
        serialDevice: serialDevice,
      }
    )
    checkSuccess(body)
  }

  public getSatModemSubscribers(): Observable<SatelliteSubscribers> {
    return this.db
      .object<SatelliteSubscribers>(this.paths.satModemSubscribers())
      .valueChanges()
      .pipe(takeUntil(this.auth.logouts$))
  }

  //  links the satellite device to BRNKL device in database
  public async linkSatModemToBrnklDB(
    imei: string,
    deviceId: string
  ): Promise<boolean> {
    const body = {
      imei,
    }
    const linkEndpoint = `satModem/link/${deviceId}`
    const res = await this.cloudFunctions.authedPost<FunctionsRes<void>>(
      linkEndpoint,
      body
    )

    return res.success
  }

  //  unlinks the satellite device to BRNKL device in database
  public async unlinkSatModemFromBrnklDB(deviceId: string): Promise<boolean> {
    const unlinkEndpoint = `satModem/link/${deviceId}`
    const res = await this.cloudFunctions.authedDel<FunctionsRes<void>>(
      unlinkEndpoint
    )
    return res.success
  }
}
