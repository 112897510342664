<ion-content padding no-bounce>
  <div class="login-container">
    <div class="login-container_inner">
      <div class="login-icon">
        <img src="./assets/login-logo.png" alt="BRNKL logo" />
      </div>
      <form
        [formGroup]="loginForm"
        class="login_form"
      >
        <ion-item>
          <ion-label position="stacked">Email</ion-label>
          <ion-input
            class="login_input"
            type="email"
            formControlName="email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Password</ion-label>
          <div class="login-password">
          <ion-input
            class="login_input"
            type="{{ showPassword ? 'text' : 'password' }}"
            formControlName="password"
          ></ion-input>
          <ion-button slot="end" fill="clear" (click)="handleShowPassword()" class="show-password-icon" align-self-center>
            <ion-icon
              *ngIf="showPassword==true"
              name="eye-outline"
            ></ion-icon>
            <ion-icon
              *ngIf="showPassword==false"
              name="eye-off-outline"
            ></ion-icon>
          </ion-button>
        </div>
        </ion-item>
      </form>
      <action-button
        label="Login"
        type="primary"
        [disabled]="!loginForm.valid"
        (action)="loginWithEmail()" 
        style="width: 100%;"
      ></action-button>
      <a
        href=""
        role="button"
        (click)="openForgetPasswordModal($event)"
        class="login_create-link"
      >
        Forgot Password
      </a>
      <hr class="login-divider" />
      <action-button
        label="Create Account"
        type="secondary"
        (action)="openCreatePage()"
        style="width: 100%;"
      ></action-button>
      <div class="login_version">
        <div>
          Version:
          <span>v{{appVersion}}</span>
          <span *ngIf="!isProd">(Staging)</span>
        </div>
      </div>
    </div>
  </div>
</ion-content>
