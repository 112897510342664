import { NgRedux } from '@angular-redux/store'
import { Injectable } from '@angular/core'
import firebase from 'firebase/compat/app'
import { map } from 'rxjs/operators'

import { ActionTypes } from '../../models/action-types.model'
import { AppAlert } from '../../models/app-alert.model'
import { DeviceGps } from '../../models/device-gps.model'
import { DeviceSettings } from '../../models-shared/device-settings.model'
import { AppDevice } from '../../models/app-device.model'
import { GraphState } from '../../models/graph-state.model'
import { Photo } from '../../models/photo.model'
import { Stat } from '../../models-shared/stat.model'
import { DEFAULT_STATE, State } from '../../models/state.model'
import { UserSettings } from '../../models-shared/user-settings.model'
import { AlertsProvider } from '../alerts/alerts.service'
import { AuthProvider } from '../auth/auth.service'
import { DeviceProvider } from '../device/device.service'
import { PhotosProvider } from '../photos/photos.service'
import { SettingsProvider } from '../settings/settings.service'
import { StatsProvider } from '../stats/stats.service'
import { NotificationsProvider } from '../notifications/notifications.service'
import { EnvironmentProvider } from '../environment/environment.service'

declare var cordova: any

@Injectable({
  providedIn: 'root',
})
export class SideEffectsProvider {
  private allDevicesSubscription
  private switchedStatsSubscription
  private switchedGpsSubscription
  private userSubscription
  private logoutsSubscription
  private deviceUnselectedSubscription
  private activeAlertsSubscription
  private photosSubscription
  private switchedGraphDataSubscription
  private switchedCellStrengthSubscription
  private deviceSettingsSubscription
  private userSettingsSubscription
  private deviceUsersSubscription

  constructor(
    private statsProvider: StatsProvider,
    private deviceProvider: DeviceProvider,
    private alertsProvider: AlertsProvider,
    private photosProvider: PhotosProvider,
    private auth: AuthProvider,
    private ngRedux: NgRedux<State>,
    private settingsProvider: SettingsProvider,
    private notificationsProvider: NotificationsProvider,
    private environmentProvider: EnvironmentProvider
  ) {}

  public subscribe(): void {
    // Store each subscription in the matching properties
    this.allDevicesSubscription = this.subscribeToDevices()
    this.userSubscription = this.subscribeToUser()
    this.logoutsSubscription = this.subscribeToLogout()
    this.userSettingsSubscription = this.subscribeToUserSettings()
    this.deviceUnselectedSubscription = this.subscribeToDeviceChange()
    this.switchedStatsSubscription = this.subscribeToStats()
    this.switchedGpsSubscription = this.subscribeToGps()
    this.photosSubscription = this.subscribeToPhotos()
    this.switchedGraphDataSubscription = this.subscribeToGraphData()
    this.switchedCellStrengthSubscription = this.subscribeToCellStrength()
    this.activeAlertsSubscription = this.subscribeToAlerts()
    this.deviceSettingsSubscription = this.subscribeToSettings()
    this.deviceUsersSubscription = this.subscribeToDeviceUsers()
  }

  public unsubscribe(): void {
    // Unsubscribe each subscription if it exists
    this.allDevicesSubscription?.unsubscribe()
    this.switchedStatsSubscription?.unsubscribe()
    this.switchedGpsSubscription?.unsubscribe()
    this.userSubscription?.unsubscribe()
    this.logoutsSubscription?.unsubscribe()
    this.deviceUnselectedSubscription?.unsubscribe()
    this.activeAlertsSubscription?.unsubscribe()
    this.photosSubscription?.unsubscribe()
    this.switchedGraphDataSubscription?.unsubscribe()
    this.switchedCellStrengthSubscription?.unsubscribe()
    this.deviceSettingsSubscription?.unsubscribe()
    this.userSettingsSubscription?.unsubscribe()
    this.deviceUsersSubscription?.unsubscribe()
  }

  public subscribeToDevice() {
    this.subscribeToStats()
    this.subscribeToGps()
    this.subscribeToPhotos()
    this.subscribeToGraphData()
    this.subscribeToCellStrength()
    this.subscribeToAlerts()
    this.subscribeToSettings()
    this.subscribeToDeviceUsers()
  }

  public setCurrentDeviceId(device: AppDevice): void {
    this.deviceProvider.setCurrentDeviceId(device.deviceId)
  }

  private subscribeToDevices(): void {
    this.deviceProvider.allDevices$.subscribe((allDevices: AppDevice[]) => {
      let alertCount = 0
      const deviceIds: string[] = allDevices
        .filter((device) => device != null)
        .map((device) => {
          if (device.settings.alertsConfig?.newAlertCount) {
            alertCount = alertCount + device.settings.alertsConfig.newAlertCount
          }
          return device.deviceId
        })

      this.notificationsProvider.registerAppDevice(deviceIds)
      if (this.environmentProvider.isMobileDevice()) {
        cordova.plugins.notification.badge.set(alertCount)
      }
      alertCount = 0
      this.ngRedux.dispatch({
        type: ActionTypes.SET_ALL_DEVICES,
        allDevices,
      })
      // only one device, so load that device
      if (allDevices.length === 1 && allDevices[0] != null) {
        this.setCurrentDeviceId(allDevices[0])
      }
    })
  }

  private subscribeToStats(): void {
    this.statsProvider.switchedStats$.subscribe((stats: Stat<any>[]) => {
      this.ngRedux.dispatch({
        type: ActionTypes.SET_CURRENT_DEVICE_STATUS,
        stats,
      })
    })
  }

  private subscribeToGps(): void {
    this.statsProvider.switchedGps$.subscribe((gps: DeviceGps) => {
      this.ngRedux.dispatch({
        type: ActionTypes.SET_CURRENT_DEVICE_GPS,
        gps,
      })
    })
  }

  private subscribeToUser(): void {
    // Note: we map null/undefined users to an
    // empty object here such that
    // the UI knows if we have _no_
    // auth, or unknown auth
    this.auth.user
      .pipe(map((user) => user || {}))
      .subscribe((user: firebase.User) => {
        this.ngRedux.dispatch({
          type: ActionTypes.SET_CURRENT_USER,
          user,
        })
      })
  }

  private subscribeToLogout(): void {
    this.auth.logouts$.subscribe(() => {
      this.deviceProvider.setCurrentDeviceId(null)
      this.ngRedux.dispatch({
        type: ActionTypes.FLUSH_TO_DEFAULT_STATE,
        defaultState: {
          ...DEFAULT_STATE,
          currentUser: {},
        },
      })
    })
  }

  private subscribeToDeviceChange(): void {
    this.deviceProvider.deviceUnselected$.subscribe(() => {
      this.photosProvider.reset()
      this.alertsProvider.reset()
      this.ngRedux.dispatch({
        type: ActionTypes.FLUSH_CURRENT_DEVICE,
        defaultDeviceState: DEFAULT_STATE.currentDevice,
      })
    })
  }

  private subscribeToAlerts(): void {
    this.alertsProvider.activeAlerts$.subscribe((alerts: AppAlert[]) => {
      this.ngRedux.dispatch({
        type: ActionTypes.SET_CURRENT_DEVICE_ALERTS,
        alerts,
      })
    })
  }

  private subscribeToPhotos(): void {
    this.photosProvider.photos$.subscribe((photos: Photo[]) => {
      this.ngRedux.dispatch({
        type: ActionTypes.SET_CURRENT_DEVICE_PHOTOS,
        photos,
      })
    })
  }

  private subscribeToGraphData(): void {
    this.statsProvider.switchedGraphData$.subscribe(
      (graphState: GraphState) => {
        this.ngRedux.dispatch({
          type: ActionTypes.SET_CURRENT_DEVICE_GRAPH_DATA,
          graphInfo: graphState.graphInfo,
          nHoursGraphData: graphState.nHoursGraphData,
        })
        this.ngRedux.dispatch({
          type: ActionTypes.SET_CURRENT_DEVICE_GRAPH_LOADING,
          isGraphLoading: false,
        })
      }
    )
  }

  private subscribeToCellStrength(): void {
    this.statsProvider.switchedCellStrength$.subscribe((cellstrength) => {
      this.ngRedux.dispatch({
        type: ActionTypes.SET_CURRENT_DEVICE_CELL_STRENGTH,
        cellstrength,
      })
    })
  }

  private subscribeToSettings(): void {
    this.settingsProvider.deviceSettings$.subscribe(
      (settings: DeviceSettings) => {
        this.ngRedux.dispatch({
          type: ActionTypes.SET_CURRENT_DEVICE_SETTINGS,
          settings,
        })
      }
    )
  }

  private subscribeToUserSettings(): void {
    this.settingsProvider.userSettings$.subscribe(
      (userSettings: UserSettings) => {
        this.ngRedux.dispatch({
          type: ActionTypes.SET_CURRENT_USER_SETTINGS,
          currentUserSettings: userSettings,
        })
      }
    )
  }

  private subscribeToDeviceUsers(): void {
    this.settingsProvider.deviceUsers$.subscribe((users) => {
      this.ngRedux.dispatch({
        type: ActionTypes.SET_CURRENT_DEVICE_USERS,
        users,
      })
    })
  }
}
